import {
  initialSharedState,
  ISharedState,
  sharedReducerFactory,
} from "@clockwise/web-commons/src/state";
import { IFeatureGridReduxState } from "@clockwise/web-commons/src/util/feature-grid.util";
import { networkFailureScale } from "@clockwise/web-commons/src/util/local-storage";
import { combineReducers } from "redux";
import { annotationDialogReducer, IAnnotationDialogState } from "./annotation-dialog.reducer";
import { authReducer, IAuthState } from "./auth.reducer";
import { IWebExtensionState, webExtensionReducer } from "./chrome-extension.reducer";
import { chromeFeedReducer, IChromeFeedState } from "./chrome-feed.reducer";
import { confettiReducer, IConfettiState } from "./confetti.reducer";
import { dayViewReducer, IDayViewState } from "./day-view.reducer";
import { errorReducer, IErrorState } from "./error.reducer";
import { featureGridReducer } from "./feature-grid.reducer";
import { feedSchedulerReducer, IFeedSchedulerState } from "./feed-scheduler.reducer";
import { IMultiCalendarState, multiCalendarReducer } from "./multi-calendar.reducer";
import { IOnboardingState, onboardingReducer } from "./onboarding.reducer";
import { IPaymentsState, paymentsReducer } from "./payments.reducer";
import { IPlannerHighlightState, plannerHighlightReducer } from "./planner-highlight.reducer";
import {
  IRescheduleEventModalState,
  rescheduleEventModalReducer,
} from "./reschedule-event-modal.reducer";
import { IReschedulerState, reschedulerReducer } from "./rescheduler.reducer";
import { ISettingsState, settingsReducer } from "./settings.reducer";
import { ISnackBarState, snackBarReducer } from "./snack-bar.reducer";
import { ISudoState, sudoReducer } from "./sudo.reducer";
import { ITeamHealthState, teamHealthReducer } from "./team-health.reducer";
import uiFlowOnScreenReducer, { IUiFlowOnScreenState } from "./ui-flow-on-screen.reducer";

export interface IReduxState {
  annotationDialog: IAnnotationDialogState;
  auth: IAuthState;
  confetti: IConfettiState;
  webExtension: IWebExtensionState;
  chromeFeed: IChromeFeedState;
  dayView: IDayViewState;
  featureGrid: IFeatureGridReduxState;
  feedScheduler: IFeedSchedulerState;
  onboarding: IOnboardingState;
  error: IErrorState;
  rescheduler: IReschedulerState;
  settings: ISettingsState;
  snackBar: ISnackBarState;
  teamHealth: ITeamHealthState;
  sudo: ISudoState;
  payments: IPaymentsState;
  shared: ISharedState;
  plannerHighlights: IPlannerHighlightState;
  multiCalendar: IMultiCalendarState;
  uiFlowOnScreen: IUiFlowOnScreenState;
  rescheduleEventModal: IRescheduleEventModalState;
}

// combine all reducers here
export const rootReducer = combineReducers<IReduxState>({
  annotationDialog: annotationDialogReducer,
  auth: authReducer as any,
  confetti: confettiReducer,
  webExtension: webExtensionReducer as any,
  chromeFeed: chromeFeedReducer,
  dayView: dayViewReducer as any,
  error: errorReducer,
  featureGrid: featureGridReducer,
  feedScheduler: feedSchedulerReducer as any,
  onboarding: onboardingReducer,
  rescheduler: reschedulerReducer,
  settings: settingsReducer,
  snackBar: snackBarReducer,
  sudo: sudoReducer,
  teamHealth: teamHealthReducer,
  payments: paymentsReducer,
  plannerHighlights: plannerHighlightReducer,
  multiCalendar: multiCalendarReducer,
  uiFlowOnScreen: uiFlowOnScreenReducer,
  rescheduleEventModal: rescheduleEventModalReducer,
  // TODO: synced state
  // shared between chrome-extension and webapp
  shared: sharedReducerFactory(
    {
      ...initialSharedState,
      networkFailureScale: networkFailureScale.get(),
    },
    undefined, // PostMessageManager.sendSyncedAction,
    undefined, // (initialState, otherInitialState) => ({
    //   ...initialState,
    //   ...otherInitialState,
    // }),
    (prevState, newState) => {
      if (prevState.networkFailureScale !== newState.networkFailureScale) {
        networkFailureScale.set(newState.networkFailureScale);
      }
    },
  ),
});
