//////////////////
// IMPORTS
//////////////////
// schema
import * as ISchema from "#webapp/src/__schema__";
// libraries
import * as React from "react";
import { connect } from "react-redux";
import { createRefetchContainer } from "react-relay";
// org-user-select-form imports
import { fragments, refetchQuery } from "./OrgUserSelectForm.gql";
import * as s from "./OrgUserSelectForm.styles";
import { AugmentedPersonMap, IContainer, IProps, IState } from "./OrgUserSelectFormTypes";
// other internals
import { OrgUserCheckbox } from "./org-user-checkbox";
// util
import { compareBooleans, compareStrings } from "#webapp/src/util/sort.util";
// state
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";

// material-ui
import { Button, TextField } from "@clockwise/design-system";
import { Search } from "@clockwise/design-system/icons";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

//////////////////
// COMPONENT
//////////////////
export class OrgUserSelectFormComponent extends React.Component<IProps, IState> {
  public static ClipCount = 5;

  constructor(props: IProps) {
    super(props);

    const currentPersons = this.getAugmentedPersons(props);
    this.state = {
      currentPersons,
      query: "",
      loading: true,
      currentSelectedUserIds: props.selectedUserIds,
    };
  }

  // ~-~-~-~-~-~-~-
  // Lifecycle
  // ~-~-~-~-~-~-~-

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const currentPersons = this.getAugmentedPersons(nextProps);
    const newState: Partial<IState> = {
      currentPersons,
    };
    if (Object.keys(currentPersons).length && this.state.loading) {
      newState.loading = false;
    }
    this.setState(newState as IState);
  }

  public componentDidMount() {
    this.setState({ loading: false });
  }

  // ~-~-~-~-~-~-~-
  // Helpers
  // ~-~-~-~-~-~-~-
  private getAugmentedPersons = (props: IProps) => {
    const org = props.org;
    const query = this.state && this.state.query;

    // sanity check
    if (!org) {
      return {} as AugmentedPersonMap;
    }

    // use either query result or corpus
    let persons: ISchema.IOrgPerson[] = [];

    const currentOrgPersons = props.org.currentOrgPersons;
    if (currentOrgPersons.__typename !== "OrgPersonList") {
      return {} as AugmentedPersonMap;
    }

    persons = currentOrgPersons.list.filter((aop) => {
      const queryableString = `${(aop.profile && aop.profile.givenName) || ""}${
        (aop.profile && aop.profile.familyName) || ""
      }${aop.primaryCalendarId}`;
      return queryableString.includes(query);
    });

    // if a teammate leaves the org, their person won't return
    // check current teammates for anybody missing and create shim persons for them
    // const missingPersons: ISchema.IOrgPerson[] = [];
    // const personEmails = persons.map(p => p.primaryCalendarId);
    // const missing = this.props.selectedUserIds.filter(s => personEmails.indexOf(s) === -1);

    // missing.forEach((ms) => {
    //   missingPersons.push({
    //     primaryCalendarId: ms,
    //   } as ISchema.IOrgPerson);
    // });
    // if (missingPersons.length) {
    //   persons = [...persons, ...missingPersons];
    // }

    const result: AugmentedPersonMap = persons
      .filter((person) => {
        if (!this.props.showCurrentUser && person.isYou) {
          return false;
        }
        return true;
      })
      .reduce((accumulator: AugmentedPersonMap, nextPerson): AugmentedPersonMap => {
        const { profile, primaryCalendarId, userId, isYou } = nextPerson;

        // defaults
        accumulator[userId] = {
          isYou,
          userId,
          targetCalendarId: primaryCalendarId,
          givenName: profile ? profile.givenName : primaryCalendarId, // if no user profile, show primaryEmail
          familyName: profile ? profile.familyName : "",
          externalImageUrl: profile ? profile.externalImageUrl : "",
          isSuggested: false,
          numberOfSharedMeetings: 0,
          numberOfOrganizedMeetings: 0,
          inviteReason: ISchema.SuggestedOrgInviteReason.AttendSameMeetings,
          totalScore: 0,
          isPending: false, // FIXME (lsanwick) Hack to make it fit the usage correctly
          isUser: false, // FIXME (lsanwick) Hack to make it fit the usage correctly
        };

        return accumulator;
      }, {});

    return result;
  };

  private sortPersons = (persons: AugmentedPersonMap) => {
    return Object.keys(persons).sort((a, b) => {
      const personA = persons[a];
      const personB = persons[b];

      return (
        compareBooleans(personA.isYou, personB.isYou) || // you go to the bottom
        (personA.isSuggested && personB.isSuggested && personB.totalScore - personA.totalScore) || // best suggestions to the top
        compareBooleans(personA.isSuggested, personB.isSuggested, false) || // suggestions to the top (note isAscending = false)
        compareStrings(personA.givenName, personB.givenName) ||
        compareStrings(personA.familyName, personB.familyName)
      );
    });
  };

  // ~-~-~-~-~-~-~-
  // Handlers
  // ~-~-~-~-~-~-~-
  private onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    // this.props.relay.refetch({ orgRelayId: this.props.org.id, queryInput: query, excludeCalendars: [] });
    this.setState({ query }, () => {
      const currentPersons = this.getAugmentedPersons(this.props);
      this.setState({ currentPersons });
    });
  };

  private onSelectPerson = (userId: string, added: boolean, isSuggested: boolean) => {
    const userIds = this.state.currentSelectedUserIds;
    const index = userIds.findIndex((u) => u.userId === userId);
    if (added && index < 0) {
      userIds.push({ userId, isSuggested });
    } else if (!added && index > -1) {
      userIds.splice(index, 1);
    }

    this.setState({ currentSelectedUserIds: userIds });
  };

  private onConfirm = () => {
    this.props.onConfirm(this.state.currentSelectedUserIds);
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
  };

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  private renderPersons() {
    const persons = this.state.currentPersons;

    if (!Object.keys(persons).length) {
      return <div style={s.styles.empty}>No results found</div>;
    }

    return this.sortPersons(persons)
      .slice(0, 20) // for perf reasons, only render 10
      .map((userId) => {
        return (
          <OrgUserCheckbox
            key={`org-user-select-checkbox-${userId}`}
            person={{ ...persons[userId] }}
            checked={this.state.currentSelectedUserIds.findIndex((u) => u.userId === userId) > -1}
            onCheck={this.onSelectPerson}
            viewOnly={this.props.viewOnly}
          />
        );
      });
  }

  private renderCmpt = () => {
    if (this.state.loading) {
      return (
        <div className="cw-flex cw-justify-center">
          <Loader size="lg" sentiment="positive" />
        </div>
      );
    }

    return (
      <div>
        <div className="cw-relative cw-flex cw-justify-between cw-items-center">
          <div className="cw-w-[300px]">
            <TextField
              label="Search for teammates"
              startIcon={Search}
              value={this.state.query}
              onChange={this.onSearch}
            />
          </div>
        </div>
        <div>{this.renderPersons()}</div>
      </div>
    );
  };

  private renderDialog = () => {
    const { org, open, onRequestClose } = this.props;
    return (
      <Dialog
        open={!!open}
        onClose={onRequestClose}
        style={
          open
            ? {}
            : { zIndex: -99999, width: 0, height: 0, overflow: "hidden", bottom: 0, right: 0 }
        }
        disableBackdropClick
      >
        <DialogTitle>
          <div>
            Users in your organization (
            {org.currentOrgPersons.__typename === "OrgPersonList"
              ? org.currentOrgPersons.list.length
              : 0}
            )
          </div>
          <div style={s.styles.mixedOrgCallout}>
            Clockwise Teams plan grants seats to all users in your organization to coordinate across
            multiple schedules, maximizing everyone's productivity.
          </div>
        </DialogTitle>
        <DialogContent>{this.renderCmpt()}</DialogContent>
        <DialogActions>
          <Button onClick={this.onConfirm} sentiment="positive">
            {/* Confirm {this.state.currentSelectedUserIds.length} paid users */}
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  public render() {
    const { isDialog } = this.props;

    if (isDialog) {
      return this.renderDialog();
    } else {
      return this.renderCmpt();
    }
  }
}

//////////////////
// REDUX
//////////////////
function mapStateToProps(_state: IReduxState, _ownProps: IContainer) {
  return {}; // add redux state here, if needed
}

const reduxContainer = connect(mapStateToProps, {})(OrgUserSelectFormComponent);

//////////////////
// RELAY
//////////////////
export const OrgUserSelectForm = createRefetchContainer(reduxContainer, fragments, refetchQuery);
