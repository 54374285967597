export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateInterval: string;
  DateTime: string;
  DateTimeInterval: string;
  /** ISO duration string, e.g. PT1H30M */
  Duration: string;
  IANAZone: string;
  RecurrenceRule: string;
  /** ISO time string, without a date. e.g. 16:30 or 16:30:00 */
  Time: string;
  Upload: any;
  /** A completely null field, for types that are in a union but have no fields. */
  Void: any;
};

/** A summary of an event we are proposing to add to a user's calendar */
export type AddDiff = Diff & {
  __typename: "AddDiff";
  action: DiffAction;
  active: Scalars["Boolean"];
  ambiguities: Array<AttendeeAmbiguity>;
  attendees: Array<Attendee>;
  description: Scalars["String"];
  id: Scalars["ID"];
  isFlexible: Scalars["Boolean"];
  recurrence?: Maybe<SimpleRecurrence>;
  recurrenceRule?: Maybe<Scalars["RecurrenceRule"]>;
  /** Null if there were no times found */
  time?: Maybe<DateOrTimeRange>;
  title: Scalars["String"];
  /** Will be empty in the case of consequences, as those have no tradeoffs */
  tradeoffBlocks: Array<TradeoffBlock>;
};

export type AddTeamMembersInput = {
  memberIds: Array<Scalars["ID"]>;
  teamId: Scalars["ID"];
};

export type Attendee = {
  __typename: "Attendee";
  email: Scalars["String"];
  id: Scalars["ID"];
  isExternal?: Maybe<Scalars["Boolean"]>;
  isOptional?: Maybe<Scalars["Boolean"]>;
  isOrganizer?: Maybe<Scalars["Boolean"]>;
  /** Member will only exist if this attendee is a member of the organization. */
  member?: Maybe<Member>;
  person: Person;
  responseStatus?: Maybe<ResponseStatusEnum>;
};

export type AttendeeAmbiguity = {
  __typename: "AttendeeAmbiguity";
  attendeeRequested: Scalars["String"];
  isGroup: Scalars["Boolean"];
  /** Email addresses of possible attendees */
  possibleAttendees: Array<Person>;
};

export type AttendeeInput = {
  email: Scalars["String"];
  name: Scalars["String"];
  questionResponses: Array<QuestionResponseInput>;
};

export type AttendeeQuestion = {
  __typename: "AttendeeQuestion";
  id: Scalars["ID"];
  question: Scalars["String"];
  required: Scalars["Boolean"];
  type: AttendeeQuestionTypeEnum;
};

export type AttendeeQuestionInput = {
  question: Scalars["String"];
  required: Scalars["Boolean"];
  type: AttendeeQuestionTypeEnum;
};

export enum AttendeeQuestionTypeEnum {
  LongText = "LongText",
  PhoneNumber = "PhoneNumber",
  ShortText = "ShortText",
}

export type AvailabilityRange = FixedWindow | RollingWindow;

/**
 * The time range in which a booking can be made, should be specified with either `windowSize` or
 * `startDate` and `endDate`.
 */
export type AvailabilityRangeInput = {
  endDate?: InputMaybe<Scalars["Date"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  /** In days */
  windowSize?: InputMaybe<Scalars["Int"]>;
};

export enum AvailabilityRestrictionEnum {
  BalanceAvailabilityAndFocus = "BalanceAvailabilityAndFocus",
  MaximizeAvailability = "MaximizeAvailability",
  MaximizeFocusTime = "MaximizeFocusTime",
}

export enum AvailabilityStatus {
  AfterHours = "AfterHours",
  BeforeWorkingHours = "BeforeWorkingHours",
  DayOff = "DayOff",
  FocusTime = "FocusTime",
  Holiday = "Holiday",
  Lunch = "Lunch",
  Meeting = "Meeting",
  Other = "Other",
  OutOfOffice = "OutOfOffice",
  Weekend = "Weekend",
  WorkingHours = "WorkingHours",
}

export type BillingGroup = {
  __typename: "BillingGroup";
  admins: Array<BillingGroupMember>;
  id: Scalars["ID"];
  linkLogoUrl?: Maybe<Scalars["String"]>;
  members: Array<BillingGroupMember>;
  name: Scalars["String"];
};

export type BillingGroupMember = {
  __typename: "BillingGroupMember";
  admin: Scalars["Boolean"];
  billingGroup: BillingGroup;
  id: Scalars["ID"];
  member: Member;
};

export enum BookableHoursEnum {
  CustomHours = "CustomHours",
  MeetingHours = "MeetingHours",
  WorkingHours = "WorkingHours",
}

export type BookingInput = {
  additionalAttendeeEmails?: InputMaybe<Array<Scalars["String"]>>;
  attendee: AttendeeInput;
  duration?: InputMaybe<Scalars["Int"]>;
  linkName: Scalars["ID"];
  slug: Scalars["String"];
  startTime: Scalars["DateTime"];
  timeZone: Scalars["IANAZone"];
};

export type BookingLimit = {
  __typename: "BookingLimit";
  count: Scalars["Int"];
  period: BookingLimitPeriodEnum;
};

export type BookingLimitInput = {
  count: Scalars["Int"];
  period: BookingLimitPeriodEnum;
};

export enum BookingLimitPeriodEnum {
  Daily = "Daily",
  Weekly = "Weekly",
}

/** Data about a single calendarId in our system */
export type Calendar = {
  __typename: "Calendar";
  displayName: Scalars["String"];
  eventsByDates: Array<Maybe<Event>>;
  eventsByWeek: Array<Event>;
  id: Scalars["ID"];
  workingLocationEvent?: Maybe<WorkingLocationEvent>;
  workingLocationEventsByWeek: Array<WorkingLocationEvent>;
};

/** Data about a single calendarId in our system */
export type CalendarEventsByDatesArgs = {
  end: Scalars["Date"];
  start: Scalars["Date"];
  timeZone: Scalars["IANAZone"];
};

/** Data about a single calendarId in our system */
export type CalendarEventsByWeekArgs = {
  timeZone: Scalars["IANAZone"];
  weekStartDate: Scalars["Date"];
};

/** Data about a single calendarId in our system */
export type CalendarWorkingLocationEventArgs = {
  calendarId: Scalars["ID"];
  externalEventId: Scalars["ID"];
};

/** Data about a single calendarId in our system */
export type CalendarWorkingLocationEventsByWeekArgs = {
  timeZone: Scalars["IANAZone"];
  weekStartDate: Scalars["Date"];
};

export type CalendarTimeStats = {
  __typename: "CalendarTimeStats";
  busyMinutes: Scalars["Int"];
  focusMinutes: Scalars["Int"];
  focusTimeGoal?: Maybe<Scalars["Int"]>;
  fragmentedMinutes: Scalars["Int"];
  id: Scalars["ID"];
  totalMinutes: Scalars["Int"];
  unclassifiedMinutes: Scalars["Int"];
};

export type CancelBookingInput = {
  id: Scalars["ID"];
  /** Reason for cancelation. */
  reason?: InputMaybe<Scalars["String"]>;
};

/** A list of messages that make up a conversation between a user and Clockwise AI. */
export type ChatHistory = {
  __typename: "ChatHistory";
  /** Current user ID */
  id: Scalars["ID"];
  /** Whether or not the user's message is still being processed */
  isProcessing: Scalars["Boolean"];
  messages: Array<ChatMessage>;
  /** Whether or not the user can use Clockwise AI's Chat functionality */
  shouldExcludeAi: Scalars["Boolean"];
};

/** Either a user message or an assistant response: text, view events, or a (re)scheduling proposal */
export type ChatMessage =
  | ProposalResponse
  | SchedulingLinksResponse
  | TextResponse
  | UserMessage
  | ViewEventsResponse;

export type ClockwiseSubscription = {
  __typename: "ClockwiseSubscription";
  id: Scalars["ID"];
  productType: ProductType;
  type: SubscriptionType;
};

export type Collaborator = {
  __typename: "Collaborator";
  availabilityStatus?: Maybe<AvailabilityStatus>;
  externalImageUrl?: Maybe<Scalars["String"]>;
  fullName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  sharedMinutes?: Maybe<Scalars["Int"]>;
  usersTimeZone?: Maybe<Scalars["IANAZone"]>;
};

export type Conferencing = {
  __typename: "Conferencing";
  type: ConferencingType;
  url: Scalars["String"];
};

export enum ConferencingType {
  Gather = "GATHER",
  Meet = "MEET",
  /** Used as an input to indicate the conferencing should be removed. Will never be returned from a query. */
  None = "NONE",
  Other = "OTHER",
  TeamsForBusiness = "TEAMS_FOR_BUSINESS",
  Zoom = "ZOOM",
}

export type ConfirmProposalInput = {
  appliesTo: ProposalAppliesTo;
  id: Scalars["ID"];
};

export type ConsequencesBlock = {
  __typename: "ConsequencesBlock";
  /** Formatted label of the changes, in AI Markdown */
  changes?: Maybe<Scalars["String"]>;
  othersDiffs: Array<OthersDiff>;
  yourDiffs: Array<ModifyDiff>;
};

export type CreateEventInput = {
  attendees: Array<EventAttendeeInput>;
  conferenceType?: InputMaybe<ConferencingType>;
  description?: InputMaybe<Scalars["String"]>;
  flexDetails?: InputMaybe<FlexDetailsInput>;
  location?: InputMaybe<Scalars["String"]>;
  recurrenceRule?: InputMaybe<Scalars["RecurrenceRule"]>;
  timeRange: Scalars["DateTimeInterval"];
  timeZone: Scalars["IANAZone"];
  title?: InputMaybe<Scalars["String"]>;
};

export type CreateProposalFromEventInput = {
  eventId: Scalars["ID"];
  timeRange: Scalars["DateTimeInterval"];
  timeZone: Scalars["IANAZone"];
};

export type CreateProposalInput = {
  attendees: Array<EventAttendeeInput>;
  timeRange: Scalars["DateTimeInterval"];
  timeZone: Scalars["IANAZone"];
  title?: InputMaybe<Scalars["String"]>;
};

export type CreateSchedulingLinkFromDraftEventInput = {
  eventDiffId: Scalars["String"];
};

export type CreateTeamInput = {
  setAsPrimary: Scalars["Boolean"];
  /** Must be a list of personIds, which are distinct from user or calendar ids. They are an auto-increment id. */
  teamMembers: Array<Scalars["ID"]>;
  teamName: Scalars["String"];
};

/** Represents available hours for a specific date (e.g, 12pm—3pm on 2024-03-11) */
export type CustomAvailabilityByDate = {
  __typename: "CustomAvailabilityByDate";
  date: Scalars["Date"];
  timeRanges: Array<CustomAvailabilityTimeRange>;
};

/** Represents available hours for a specific date (e.g, 12pm—3pm on 2024-03-11) */
export type CustomAvailabilityByDateInput = {
  date: Scalars["Date"];
  timeRanges: Array<CustomAvailabilityTimeRangeInput>;
};

/** Represents available hours for a given day of the week (e.g., 9am—5pm on Mondays) */
export type CustomAvailabilityByDay = {
  __typename: "CustomAvailabilityByDay";
  day: DayEnum;
  timeRanges: Array<CustomAvailabilityTimeRange>;
};

/** Represents available hours for a given day of the week (e.g., 9am—5pm on Mondays) */
export type CustomAvailabilityByDayInput = {
  day: DayEnum;
  timeRanges: Array<CustomAvailabilityTimeRangeInput>;
};

export type CustomAvailabilityTimeRange = {
  __typename: "CustomAvailabilityTimeRange";
  endTime: Scalars["String"];
  startTime: Scalars["String"];
};

export type CustomAvailabilityTimeRangeInput = {
  endTime: Scalars["String"];
  startTime: Scalars["String"];
};

export type CustomMeetingHours = {
  __typename: "CustomMeetingHours";
  dateOverrides: Array<CustomAvailabilityByDate>;
  schedule: Array<CustomAvailabilityByDay>;
};

export type CustomMeetingHoursInput = {
  dateOverrides: Array<CustomAvailabilityByDateInput>;
  schedule: Array<CustomAvailabilityByDayInput>;
};

export type DateOrTimeRange = DateRange | DateTimeRange;

/** A ISO8601 date range, unzoned, with no time component. (e.g. 2024-05-01/2024-05-31) */
export type DateRange = {
  __typename: "DateRange";
  range: Scalars["DateInterval"];
};

/** An ISO8601 time range with a start and end time. (e.g. 2024-05-01T16:30:00Z/2024-05-31T17:30:00Z) */
export type DateTimeRange = {
  __typename: "DateTimeRange";
  range: Scalars["DateTimeInterval"];
};

export enum DayEnum {
  Friday = "Friday",
  Monday = "Monday",
  Saturday = "Saturday",
  Sunday = "Sunday",
  Thursday = "Thursday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
}

export enum DayOfWeek {
  Friday = "Friday",
  Monday = "Monday",
  Saturday = "Saturday",
  Sunday = "Sunday",
  Thursday = "Thursday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
}

export type DeleteEventInput = {
  calendarId: Scalars["ID"];
  email: Scalars["String"];
  externalEventId: Scalars["ID"];
  patchAppliesTo: EditEventAppliesTo;
};

export type DeleteWorkingLocationEventInput = {
  calendarId: Scalars["ID"];
  externalEventId: Scalars["ID"];
  patchAppliesTo: EditEventAppliesTo;
};

export type DeleteZoomAccountInput = {
  accountId: Scalars["ID"];
};

/** A diff summarizing the change we are proposing to a user's calendar */
export type Diff = {
  action: DiffAction;
  active: Scalars["Boolean"];
  ambiguities: Array<AttendeeAmbiguity>;
  description: Scalars["String"];
  id: Scalars["ID"];
  isFlexible: Scalars["Boolean"];
  title: Scalars["String"];
};

export enum DiffAction {
  Accept = "Accept",
  Add = "Add",
  Cancel = "Cancel",
  Decline = "Decline",
  EditDetails = "EditDetails",
  ProposeNewTime = "ProposeNewTime",
  Reschedule = "Reschedule",
  Tentative = "Tentative",
  Unchanged = "Unchanged",
}

/** Text describing the proposed changes to a user's calendar along with a list of diffs representing the changes */
export type DiffBlock = {
  __typename: "DiffBlock";
  diffs: Array<Diff>;
  text: Scalars["String"];
};

export type DisablePersonalSyncInput = {
  externalEventId: Scalars["ID"];
};

export type DuplicateSchedulingLinkInput = {
  linkName: Scalars["String"];
  slug: Scalars["ID"];
};

export enum EditEventAppliesTo {
  AllInstances = "AllInstances",
  Instance = "Instance",
  ThisAndFuture = "ThisAndFuture",
}

export type EnableOrDisableTeamSyncEventInput = {
  enabled: Scalars["Boolean"];
  externalEventId: Scalars["ID"];
};

export type Event = EventInterface & {
  __typename: "Event";
  areAttendeesOmitted: Scalars["Boolean"];
  attendees: Array<Attendee>;
  calendar: Calendar;
  /** @deprecated Use new `calendar` field */
  calendarId: Scalars["ID"];
  canMarkFlexible: Scalars["Boolean"];
  canReschedule: Scalars["Boolean"];
  category: EventCategory;
  conferencing?: Maybe<Conferencing>;
  dateOrTimeRange: DateOrTimeRange;
  description?: Maybe<Scalars["String"]>;
  duration: Scalars["Duration"];
  eventPermissions: EventPermissions;
  externalEventId: Scalars["ID"];
  flexibility?: Maybe<FlexDetails>;
  history: EventLog;
  id: Scalars["ID"];
  isAllDay: Scalars["Boolean"];
  isCanceled: Scalars["Boolean"];
  /** Determines if the event can be collapsed in the UI. */
  isCollapsible: Scalars["Boolean"];
  isDescriptionOmitted: Scalars["Boolean"];
  isHold: Scalars["Boolean"];
  isLocked: Scalars["Boolean"];
  /** @deprecated Use transparency */
  isMarkedBusy: Scalars["Boolean"];
  /** Whether this event is on the current user's calendar. */
  isMyEvent: Scalars["Boolean"];
  isOutOfOffice: Scalars["Boolean"];
  isSyncedFromPersonalCalendar: Scalars["Boolean"];
  isSyncedToTeamCalendar: Scalars["Boolean"];
  location?: Maybe<Scalars["String"]>;
  /** @deprecated Use transparency */
  microsoftTransparency?: Maybe<TransparencyEnum>;
  organizer?: Maybe<Attendee>;
  /** @deprecated Use recurrenceRule instead */
  recurrence?: Maybe<Recurrence>;
  recurrenceRule?: Maybe<Scalars["RecurrenceRule"]>;
  recurringEventId?: Maybe<Scalars["ID"]>;
  /** Response status of the calendar to which the event belongs */
  responseStatus: ResponseStatusEnum;
  rooms: Array<Maybe<Room>>;
  smartHold?: Maybe<SmartHold>;
  syncedFromEvent?: Maybe<Event>;
  /** Human readable description of the event time & recurrence, including flexibility if applicable. */
  timeDescription: Scalars["String"];
  title: Scalars["String"];
  transparency?: Maybe<TransparencyEnum>;
  visibility: VisibilityEnum;
};

export type EventAttendeeInput = {
  email: Scalars["String"];
  isOrganizer?: InputMaybe<Scalars["Boolean"]>;
  optional: Scalars["Boolean"];
};

export type EventCategory = {
  __typename: "EventCategory";
  /** Number assingments for Google VS Outlook (vs future calendars?). UI Hex values defined in client */
  color?: Maybe<Scalars["String"]>;
  type: EventCategoryType;
};

export enum EventCategoryType {
  AdHoc = "AdHoc",
  External = "External",
  FocusTime = "FocusTime",
  Holiday = "Holiday",
  IndividualHold = "IndividualHold",
  OneOnOne = "OneOnOne",
  Other = "Other",
  OutOfOffice = "OutOfOffice",
  PaceSetting = "PaceSetting",
}

export type EventFlexibilityInput = {
  allowedDays?: InputMaybe<Array<DayOfWeek>>;
  externalEventId: Scalars["ID"];
  flexRange?: InputMaybe<FlexRange>;
  isFlexible: Scalars["Boolean"];
  timeRange?: InputMaybe<TimeRangeInput>;
};

export type EventInterface = {
  areAttendeesOmitted: Scalars["Boolean"];
  attendees: Array<Attendee>;
  calendarId: Scalars["ID"];
  dateOrTimeRange: DateOrTimeRange;
  externalEventId: Scalars["ID"];
  flexibility?: Maybe<FlexDetails>;
  id: Scalars["ID"];
  isAllDay: Scalars["Boolean"];
  organizer?: Maybe<Attendee>;
};

export type EventLog = {
  __typename: "EventLog";
  entries: Array<EventLogEntry>;
  status: EventLogStatus;
};

export type EventLogEntry = {
  __typename: "EventLogEntry";
  details: Array<Scalars["String"]>;
  eventNewStartTime?: Maybe<Scalars["DateTime"]>;
  eventOriginalStartTime?: Maybe<Scalars["DateTime"]>;
  sourceCalendarId?: Maybe<Scalars["ID"]>;
  timestamp: Scalars["DateTime"];
  title: Scalars["String"];
  type: EventLogType;
};

export enum EventLogStatus {
  Incomplete = "Incomplete",
  NotVisible = "NotVisible",
  Off = "Off",
  On = "On",
}

export enum EventLogType {
  ClockwiseCouldNotResolveConflict = "ClockwiseCouldNotResolveConflict",
  ClockwiseEventRescheduled = "ClockwiseEventRescheduled",
  ClockwiseMeetingMove = "ClockwiseMeetingMove",
  ClockwiseMovePaused = "ClockwiseMovePaused",
  ClockwiseMoveUnpaused = "ClockwiseMoveUnpaused",
  ClockwiseSecondaryReschedule = "ClockwiseSecondaryReschedule",
  EventCreated = "EventCreated",
  EventRescheduled = "EventRescheduled",
  FlexTurnedOff = "FlexTurnedOff",
  FlexTurnedOn = "FlexTurnedOn",
  NextFlexRun = "NextFlexRun",
}

export enum EventModificationScope {
  AllInstances = "AllInstances",
  ThisAndFutureInstances = "ThisAndFutureInstances",
  /** For a non-recurring event, this is the only possible scope */
  ThisInstance = "ThisInstance",
}

/** What the current user can do with an event */
export type EventPermissions = {
  __typename: "EventPermissions";
  canDelete: Scalars["Boolean"];
  canInviteOthers: Scalars["Boolean"];
  canModify: Scalars["Boolean"];
  canRSVP: Scalars["Boolean"];
  canRemove: Scalars["Boolean"];
  canSeeOtherGuests: Scalars["Boolean"];
};

/** What the current user can do with an event */
export type EventPermissionsCanInviteOthersArgs = {
  modificationScope?: InputMaybe<EventModificationScope>;
};

/** What the current user can do with an event */
export type EventPermissionsCanModifyArgs = {
  modificationScope?: InputMaybe<EventModificationScope>;
};

export enum EventRole {
  Attendee = "Attendee",
  Editor = "Editor",
  Inviter = "Inviter",
  Owner = "Owner",
  Reader = "Reader",
}

export type FixedWindow = {
  __typename: "FixedWindow";
  endDate: Scalars["Date"];
  startDate: Scalars["Date"];
};

export type FlexDetails = {
  __typename: "FlexDetails";
  allowedDays?: Maybe<Array<DayOfWeek>>;
  canMoveAnyTime?: Maybe<Scalars["Boolean"]>;
  flexRange?: Maybe<FlexRange>;
  isFlexible: Scalars["Boolean"];
  moveableUntil?: Maybe<Scalars["Duration"]>;
  status: FlexStatus;
  timeOfDayFlexRange?: Maybe<TimeRange>;
};

export type FlexDetailsInput = {
  allowedDays?: InputMaybe<Array<DayOfWeek>>;
  flexRange?: InputMaybe<FlexRange>;
  isFlexible: Scalars["Boolean"];
  timeOfDayRange?: InputMaybe<TimeRangeInput>;
};

export enum FlexRange {
  Day = "Day",
  SpecificDays = "SpecificDays",
  Week = "Week",
}

export enum FlexStatus {
  CanMove = "CanMove",
  EventExceedsDefragRange = "EventExceedsDefragRange",
  EventOutsideTimeRange = "EventOutsideTimeRange",
  External = "External",
  NoDuration = "NoDuration",
  OverQuota = "OverQuota",
  Paused = "Paused",
  Pinned = "Pinned",
  PrivateEvent = "PrivateEvent",
  SmartHold = "SmartHold",
  TrialExpired = "TrialExpired",
}

export type FlexibleMeetingsDashboard = {
  __typename: "FlexibleMeetingsDashboard";
  flexibleMeetings: Array<Event>;
  holds: Array<Event>;
  id: Scalars["ID"];
  oneOnOneAverage: Scalars["Int"];
  oneOnOnes: Array<Event>;
  recurringMeetings: Array<Event>;
  suggestedFlexibleMeetings: Array<Event>;
};

export type FlexibleMeetingsDashboardV2 = {
  __typename: "FlexibleMeetingsDashboardV2";
  holds: Array<Event>;
  id: Scalars["ID"];
  oneOnOnes: Array<Event>;
  others: Array<Event>;
  teamSyncs: Array<Event>;
  upcomingAdHoc: Array<Event>;
};

export type FocusTimeSettings = {
  __typename: "FocusTimeSettings";
  autoDeclineEnabled: Scalars["Boolean"];
  /** minimum number of focus time per week (in hours), if autoDeclineEnabled is true, then meeting will start to be declined */
  autoDeclineThreshold: Scalars["Duration"];
  /** max focus time per day, in hours */
  dailyMaxAmount: Scalars["Duration"];
  dailyMaxEnabled: Scalars["Boolean"];
  enabled: Scalars["Boolean"];
  id: Scalars["ID"];
  markAsBusy: Scalars["Boolean"];
  /** max focus time per week, in hours */
  maxPerWeek: Scalars["Duration"];
  notify: Scalars["Boolean"];
  preferredFocusTime: PreferredFocusTime;
};

export type Group = {
  __typename: "Group";
  complete: Scalars["Boolean"];
  email: Scalars["String"];
  groupId?: Maybe<Scalars["ID"]>;
  members: Array<Maybe<Member>>;
};

export enum GroupSchedulingStrategyEnum {
  RoundRobinMaxAvailability = "RoundRobinMaxAvailability",
}

export enum HistoryStatus {
  Incomplete = "Incomplete",
  NotVisible = "NotVisible",
  Off = "Off",
  On = "On",
}

export type HomeAnalytics = {
  __typename: "HomeAnalytics";
  currentWeek?: Maybe<WeekAnalytics>;
  previousWeek?: Maybe<WeekAnalytics>;
};

export type Invitee = {
  __typename: "Invitee";
  id: Scalars["ID"];
  invited: Scalars["Boolean"];
  joined: Scalars["Boolean"];
  member: Member;
  suggested: Scalars["Boolean"];
};

export type LinkBooking = {
  __typename: "LinkBooking";
  attendees: Array<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  duration: Scalars["Int"];
  id: Scalars["ID"];
  /** Link times that are aware of the current booking and show that time range as bookable. */
  linkTimes: Array<SuggestedSlot>;
  location?: Maybe<LocationTypeEnum>;
  name: Scalars["String"];
  owner: Person;
  publicSchedulingLink?: Maybe<PublicSchedulingLink>;
  timeSlot: Scalars["DateTimeInterval"];
};

export type LinkBookingLinkTimesArgs = {
  duration?: InputMaybe<Scalars["Int"]>;
  endDate: Scalars["Date"];
  startDate: Scalars["Date"];
  timeZone: Scalars["IANAZone"];
};

export type LinkMember = {
  __typename: "LinkMember";
  id: Scalars["ID"];
  member: Member;
  owner: Scalars["Boolean"];
  required: Scalars["Boolean"];
  roundRobinEnabled: Scalars["Boolean"];
  schedulingPriority?: Maybe<SchedulingPriorityEnum>;
};

export type LinkMemberInput = {
  memberId: Scalars["ID"];
  owner: Scalars["Boolean"];
  required: Scalars["Boolean"];
  schedulingPriority?: InputMaybe<SchedulingPriorityEnum>;
};

export enum LocationTypeEnum {
  /** No videoconferencing, just information in the description */
  InDescription = "InDescription",
  /** Google Meet */
  Meet = "Meet",
  /** Microsoft Teams */
  Teams = "Teams",
  Zoom = "Zoom",
}

export type LunchHoldSettings = {
  __typename: "LunchHoldSettings";
  enabled: Scalars["Boolean"];
  id: Scalars["ID"];
  /** Maximum duration, in minutes */
  idealDuration: Scalars["Duration"];
  /** Minimum duration, in minutes */
  minDuration: Scalars["Duration"];
  notify: Scalars["Boolean"];
  timeRange: TimeRange;
};

export enum MeetingReliefSettingVisibility {
  DisabledByPaywall = "DisabledByPaywall",
  HideSetting = "HideSetting",
  ShowWithBadge = "ShowWithBadge",
  ShowWithoutBadge = "ShowWithoutBadge",
}

export type MeetingReliefSettings = {
  __typename: "MeetingReliefSettings";
  /** break duration, in minutes */
  breakDuration: Scalars["Duration"];
  /** consecutive meeting duration, in minutes */
  consecutiveMeetingDuration: Scalars["Duration"];
  enabled: Scalars["Boolean"];
  id: Scalars["ID"];
  notify: Scalars["Boolean"];
};

/** A member of an organization that is using Clockwise */
export type Member = {
  __typename: "Member";
  calendarTimeStats?: Maybe<CalendarTimeStats>;
  /** External user id */
  id: Scalars["ID"];
  person: Person;
  roundRobinEnabled: Scalars["Boolean"];
  user?: Maybe<User>;
  workingHours: Array<WorkingHours>;
  workingHoursForDate?: Maybe<WorkingHours>;
};

/** A member of an organization that is using Clockwise */
export type MemberCalendarTimeStatsArgs = {
  end: Scalars["Date"];
  start: Scalars["Date"];
  timeZone: Scalars["IANAZone"];
};

/** A member of an organization that is using Clockwise */
export type MemberWorkingHoursArgs = {
  weekStartDate: Scalars["Date"];
};

/** A member of an organization that is using Clockwise */
export type MemberWorkingHoursForDateArgs = {
  date: Scalars["Date"];
};

export type MemberConnection = {
  __typename: "MemberConnection";
  edges: Array<MemberEdge>;
  pageInfo: PageInfo;
};

export type MemberEdge = {
  __typename: "MemberEdge";
  cursor: Scalars["String"];
  node: Member;
};

export type ModifyDiff = Diff & {
  __typename: "ModifyDiff";
  action: DiffAction;
  active: Scalars["Boolean"];
  ambiguities: Array<AttendeeAmbiguity>;
  attendees: Array<Attendee>;
  currentTime: DateOrTimeRange;
  description: Scalars["String"];
  /** The event being modified */
  event?: Maybe<Event>;
  id: Scalars["ID"];
  isFlexible: Scalars["Boolean"];
  recurrence?: Maybe<SimpleRecurrence>;
  recurrenceRule?: Maybe<Scalars["RecurrenceRule"]>;
  title: Scalars["String"];
  tradeoffBlocks: Array<TradeoffBlock>;
  /** Null if the diff's time is not being updated or there were no times found */
  updatedTime?: Maybe<DateOrTimeRange>;
};

export type ModifySmartHoldInput = {
  externalEventId: Scalars["ID"];
  holdState?: InputMaybe<SmartHoldState>;
  timeRange?: InputMaybe<Scalars["DateTimeInterval"]>;
  timeZone: Scalars["IANAZone"];
};

export type ModifyTravelTimeDurationInput = {
  durationInMinutes: Scalars["Int"];
  externalEventId: Scalars["ID"];
};

export type MoveEventTimeInput = {
  appliesTo?: InputMaybe<EditEventAppliesTo>;
  externalEventId: Scalars["ID"];
  timeRange: Scalars["DateTimeInterval"];
  timeZone: Scalars["IANAZone"];
};

export type Mutation = {
  __typename: "Mutation";
  addTeamMembers?: Maybe<Team>;
  cancelBooking?: Maybe<Scalars["Boolean"]>;
  cancelProposal?: Maybe<Proposal>;
  checkLinkNameAvailable?: Maybe<Scalars["Boolean"]>;
  confirmProposal?: Maybe<Proposal>;
  /** Create a new booking for a Scheduling Link */
  createBooking?: Maybe<LinkBooking>;
  /** Create a default starting linkName for the current user, if they don't have one yet. */
  createDefaultLinkName?: Maybe<User>;
  createEvent?: Maybe<Event>;
  createProposal?: Maybe<Proposal>;
  createProposalFromEvent?: Maybe<Proposal>;
  createReschedulingLinkFromDraftEvent: ReschedulingLink;
  createSchedulingLink: SchedulingLink;
  createSchedulingLinkFromDraftEvent: SchedulingLinkWithUrl;
  createTeam?: Maybe<Team>;
  deleteCustomLinkLogo: User;
  deleteEvent?: Maybe<Scalars["Boolean"]>;
  deleteLinkLogoForBillingGroup: BillingGroup;
  deleteSchedulingLink?: Maybe<Scalars["Boolean"]>;
  deleteWorkingLocationEvent: Scalars["String"];
  deleteZoomAccount?: Maybe<Scalars["Boolean"]>;
  /** For personal calendar syncs; remove the synced event */
  disablePersonalSync?: Maybe<DisablePersonalSyncResponse>;
  duplicateSchedulingLink: SchedulingLink;
  /** For team synced events; modify the sync status of the event */
  enableOrDisableTeamSync?: Maybe<Event>;
  /** Updates the time and/or smart hold status of smarthold */
  modifySmartHold?: Maybe<Event>;
  modifyTravelTimeDuration?: Maybe<Scalars["Boolean"]>;
  /** Updates the time of any event a user can modify */
  moveEventTime?: Maybe<Event>;
  refineProposal?: Maybe<Proposal>;
  removeTeamMembers?: Maybe<Team>;
  removeTeamSlackAccount?: Maybe<Team>;
  removeTradeoffFromProposal?: Maybe<Proposal>;
  rescheduleBooking?: Maybe<Scalars["Boolean"]>;
  /** Reschedules an event to the new specified time. */
  rescheduleEvent?: Maybe<Event>;
  saveLinkSignInWaitlist?: Maybe<Scalars["Boolean"]>;
  /** Sends invites to the specified invitees */
  sendInvites: Array<Maybe<Invitee>>;
  sendProposeNewTimeEmail?: Maybe<Scalars["Boolean"]>;
  /** Send invites to the team calendar. Currently only supports M365, will be a no-op otherwise. */
  sendTeamCalendarInvites?: Maybe<Team>;
  /** Update the user's response for a specific event. */
  unpauseEventFlexibility?: Maybe<Event>;
  /** Removes the smarthold from the user's calendar */
  unsyncSmartHold?: Maybe<Event>;
  /** Update the user's custom collaborators */
  updateCustomCollaborators?: Maybe<User>;
  updateEvent?: Maybe<Event>;
  updateEventCategoryType?: Maybe<Event>;
  /** Update the user's response for a specific event. */
  updateEventResponse?: Maybe<Event>;
  /** Update one or more events' flexibility. */
  updateEventsFlexibility: Array<Maybe<Event>>;
  updateLinkActive?: Maybe<SchedulingLink>;
  updateLinkName?: Maybe<User>;
  /** Update whether Clockwise should auto-reply to meeting requests on no-meeting days. */
  updateNoMeetingAutoreplyEnabled?: Maybe<Team>;
  /** Updates the specific day of the week that is the no-meeting day for a team. */
  updateNoMeetingDay?: Maybe<Team>;
  /** Updates whether no-meeting day is enabled for a team. */
  updateNoMeetingDayEnabled?: Maybe<Team>;
  /** Update whether the current user is subscribed to the team's no-meeting day. */
  updateNoMeetingDaySubscribed?: Maybe<Team>;
  updatePreferredVideoConference?: Maybe<UserSettings>;
  /** Update which team is the current user's primary team. */
  updatePrimaryTeam?: Maybe<Team>;
  updateProposal?: Maybe<Proposal>;
  updateSchedulingLink: SchedulingLink;
  /** Update the team's calendar settings */
  updateTeamCalendarEnabled?: Maybe<Team>;
  /** Update whether the current user is subscribed to the team's calendar. */
  updateTeamCalendarSubscribed?: Maybe<Team>;
  updateTeamName?: Maybe<Team>;
  updateTeamSlack?: Maybe<Team>;
  /** Update whether WFH events should be synced to the team's calendar */
  updateTeamWFHSyncEnabled?: Maybe<Team>;
  updateUserWorkingHours: Array<WorkingHours>;
  /** Update working location event */
  updateWorkingLocationEvent: WorkingLocationEvent;
  uploadCustomLinkLogo?: Maybe<User>;
  uploadLinkLogoForBillingGroup?: Maybe<BillingGroup>;
  validateTeamSlackChannel: ValidateTeamSlackChannelPayload;
};

export type MutationAddTeamMembersArgs = {
  input: AddTeamMembersInput;
};

export type MutationCancelBookingArgs = {
  input: CancelBookingInput;
};

export type MutationCancelProposalArgs = {
  id: Scalars["ID"];
};

export type MutationCheckLinkNameAvailableArgs = {
  linkName: Scalars["String"];
};

export type MutationConfirmProposalArgs = {
  input: ConfirmProposalInput;
};

export type MutationCreateBookingArgs = {
  input: BookingInput;
};

export type MutationCreateEventArgs = {
  input: CreateEventInput;
};

export type MutationCreateProposalArgs = {
  input: CreateProposalInput;
};

export type MutationCreateProposalFromEventArgs = {
  input: CreateProposalFromEventInput;
};

export type MutationCreateReschedulingLinkFromDraftEventArgs = {
  input: CreateSchedulingLinkFromDraftEventInput;
};

export type MutationCreateSchedulingLinkArgs = {
  input: SchedulingLinkInput;
};

export type MutationCreateSchedulingLinkFromDraftEventArgs = {
  input: CreateSchedulingLinkFromDraftEventInput;
};

export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};

export type MutationDeleteEventArgs = {
  input: DeleteEventInput;
};

export type MutationDeleteLinkLogoForBillingGroupArgs = {
  billingGroupId: Scalars["ID"];
};

export type MutationDeleteSchedulingLinkArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteWorkingLocationEventArgs = {
  input: DeleteWorkingLocationEventInput;
};

export type MutationDeleteZoomAccountArgs = {
  input: DeleteZoomAccountInput;
};

export type MutationDisablePersonalSyncArgs = {
  input: DisablePersonalSyncInput;
};

export type MutationDuplicateSchedulingLinkArgs = {
  input: DuplicateSchedulingLinkInput;
};

export type MutationEnableOrDisableTeamSyncArgs = {
  input: EnableOrDisableTeamSyncEventInput;
};

export type MutationModifySmartHoldArgs = {
  input: ModifySmartHoldInput;
};

export type MutationModifyTravelTimeDurationArgs = {
  input: ModifyTravelTimeDurationInput;
};

export type MutationMoveEventTimeArgs = {
  input: MoveEventTimeInput;
};

export type MutationRefineProposalArgs = {
  input: RefineProposalInput;
};

export type MutationRemoveTeamMembersArgs = {
  input: RemoveTeamMembersInput;
};

export type MutationRemoveTeamSlackAccountArgs = {
  teamId: Scalars["ID"];
};

export type MutationRemoveTradeoffFromProposalArgs = {
  input: RemoveTradeoffFromProposalInput;
};

export type MutationRescheduleBookingArgs = {
  input: RescheduleBookingInput;
};

export type MutationRescheduleEventArgs = {
  input: RescheduleEventInput;
};

export type MutationSaveLinkSignInWaitlistArgs = {
  input: SaveLinkSignInWaitlistInput;
};

export type MutationSendInvitesArgs = {
  input: SendInviteInput;
};

export type MutationSendProposeNewTimeEmailArgs = {
  input: SendProposeNewTimeEmailInput;
};

export type MutationSendTeamCalendarInvitesArgs = {
  teamId: Scalars["ID"];
};

export type MutationUnpauseEventFlexibilityArgs = {
  input: UnpauseEventFlexibilityInput;
};

export type MutationUnsyncSmartHoldArgs = {
  input: UnsyncSmartHoldInput;
};

export type MutationUpdateCustomCollaboratorsArgs = {
  input: UpdateCustomCollaboratorsInput;
};

export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};

export type MutationUpdateEventCategoryTypeArgs = {
  input: UpdateEventCategoryTypeInput;
};

export type MutationUpdateEventResponseArgs = {
  input: UpdateEventResponseInput;
};

export type MutationUpdateEventsFlexibilityArgs = {
  input: UpdateEventsFlexibilityInput;
};

export type MutationUpdateLinkActiveArgs = {
  input: UpdateLinkActiveInput;
};

export type MutationUpdateLinkNameArgs = {
  linkName: Scalars["String"];
};

export type MutationUpdateNoMeetingAutoreplyEnabledArgs = {
  enabled: Scalars["Boolean"];
  teamId: Scalars["ID"];
};

export type MutationUpdateNoMeetingDayArgs = {
  day: DayEnum;
  teamId: Scalars["ID"];
};

export type MutationUpdateNoMeetingDayEnabledArgs = {
  enabled: Scalars["Boolean"];
  teamId: Scalars["ID"];
};

export type MutationUpdateNoMeetingDaySubscribedArgs = {
  subscribed: Scalars["Boolean"];
  teamId: Scalars["ID"];
};

export type MutationUpdatePreferredVideoConferenceArgs = {
  input: UpdatePreferredVideoConferenceInput;
};

export type MutationUpdatePrimaryTeamArgs = {
  teamId: Scalars["ID"];
};

export type MutationUpdateProposalArgs = {
  input: UpdateProposalInput;
};

export type MutationUpdateSchedulingLinkArgs = {
  input: UpdateSchedulingLinkInput;
};

export type MutationUpdateTeamCalendarEnabledArgs = {
  enabled: Scalars["Boolean"];
  teamId: Scalars["ID"];
};

export type MutationUpdateTeamCalendarSubscribedArgs = {
  subscribed: Scalars["Boolean"];
  teamId: Scalars["ID"];
};

export type MutationUpdateTeamNameArgs = {
  input: UpdateTeamNameInput;
};

export type MutationUpdateTeamSlackArgs = {
  input: UpdateTeamSlackInput;
};

export type MutationUpdateTeamWfhSyncEnabledArgs = {
  enabled: Scalars["Boolean"];
  teamId: Scalars["ID"];
};

export type MutationUpdateUserWorkingHoursArgs = {
  input: UpdateUserWorkingHoursInput;
};

export type MutationUpdateWorkingLocationEventArgs = {
  input: UpdateWorkingLocationEventInput;
};

export type MutationUploadCustomLinkLogoArgs = {
  input: UploadCustomLinkLogoInput;
};

export type MutationUploadLinkLogoForBillingGroupArgs = {
  input: UploadLinkLogoForBillingGroupInput;
};

export type MutationValidateTeamSlackChannelArgs = {
  channelName: Scalars["String"];
  teamId: Scalars["ID"];
};

export type NoMeetingDayOptions = {
  __typename: "NoMeetingDayOptions";
  autoreplyEnabled: Scalars["Boolean"];
  day?: Maybe<DayEnum>;
  enabled: Scalars["Boolean"];
};

export type OnboardingChecklist = {
  __typename: "OnboardingChecklist";
  flexMeetingEnabled: Scalars["Boolean"];
  focusTimeEnabled: Scalars["Boolean"];
  hasCompletedProposal: Scalars["Boolean"];
};

export type Org = {
  __typename: "Org";
  billingGroups: Array<BillingGroup>;
  domains: Array<Scalars["String"]>;
  id: Scalars["ID"];
  members: Array<Maybe<Member>>;
  name: Scalars["String"];
  nextDefragTime: Scalars["DateTime"];
  timeZone?: Maybe<Scalars["IANAZone"]>;
  zoomAccount?: Maybe<ZoomAccount>;
};

/** Describes a change to another user's calendar */
export type OthersDiff = Diff & {
  __typename: "OthersDiff";
  action: DiffAction;
  active: Scalars["Boolean"];
  /** When showing consequences, distinguish what attendees are affected. */
  affectedAttendees: Array<Attendee>;
  ambiguities: Array<AttendeeAmbiguity>;
  currentTime: DateOrTimeRange;
  description: Scalars["String"];
  event?: Maybe<Event>;
  id: Scalars["ID"];
  isFlexible: Scalars["Boolean"];
  title: Scalars["String"];
  /** Null if the diff's time is not being updated or there were no times found */
  updatedTime?: Maybe<DateOrTimeRange>;
};

export enum OwnerEcosystemEnum {
  Google = "GOOGLE",
  Microsoft = "MICROSOFT",
  NotApplicable = "NOT_APPLICABLE",
}

/** Page metadata for paginated queries */
export type PageInfo = {
  __typename: "PageInfo";
  endCursor?: Maybe<Scalars["String"]>;
  hasNextPage: Scalars["Boolean"];
  hasPreviousPage: Scalars["Boolean"];
  startCursor?: Maybe<Scalars["String"]>;
};

export type PaidFeatureState = {
  __typename: "PaidFeatureState";
  autopilotWhenOrganizerCurrentlyAllowed: Scalars["Boolean"];
  brandedLinksForBillingGroupsAllowed: Scalars["Boolean"];
  eligibleForNoMeetingDay: Scalars["Boolean"];
  eligibleForTeamCalendarVisibility: Scalars["Boolean"];
  focusTimeAllowed: Scalars["Boolean"];
  meetingReliefSettingVisibility: MeetingReliefSettingVisibility;
  moveRangesEnabled: Scalars["Boolean"];
  paymentStatus?: Maybe<PaymentStatus>;
  primaryBillingGroup?: Maybe<BillingGroup>;
  roundRobinEnabled: Scalars["Boolean"];
  subscription?: Maybe<ClockwiseSubscription>;
  trialMetadata?: Maybe<TrialMetadata>;
};

export enum PaymentStatus {
  Business = "Business",
  Enterprise = "Enterprise",
  Free = "Free",
  Pro = "Pro",
  Trial = "Trial",
}

export type Person = Profile & {
  __typename: "Person";
  displayName: Scalars["String"];
  email: Scalars["String"];
  externalImageUrl?: Maybe<Scalars["String"]>;
  familyName?: Maybe<Scalars["String"]>;
  givenName?: Maybe<Scalars["String"]>;
  /** Lowercase email as id. Not to be confused with calendar id. */
  id: Scalars["ID"];
  isMe: Scalars["Boolean"];
};

export type PersonalizedStatsRow = {
  __typename: "PersonalizedStatsRow";
  homeAnalytics: HomeAnalytics;
  id: Scalars["ID"];
  onboardingChecklist: OnboardingChecklist;
};

export enum PreferredFocusTime {
  Afternoon = "Afternoon",
  Morning = "Morning",
  NoPreference = "NoPreference",
}

export enum PreferredVideoConference {
  Auto = "Auto",
  Meet = "Meet",
  TeamsForBusiness = "TeamsForBusiness",
  Zoom = "Zoom",
}

export enum ProductType {
  BusinessNonStandard = "Business_NonStandard",
  BusinessStandard = "Business_Standard",
  EnterpriseNonStandard = "Enterprise_NonStandard",
  EnterpriseStandard = "Enterprise_Standard",
  ProNonStandard = "Pro_NonStandard",
  ProStandard = "Pro_Standard",
}

export type Profile = {
  displayName: Scalars["String"];
  email: Scalars["String"];
  externalImageUrl?: Maybe<Scalars["String"]>;
  familyName?: Maybe<Scalars["String"]>;
  givenName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type Proposal = {
  __typename: "Proposal";
  /**
   * What the base event diff is for this proposal, if any. Used to determine whether we can
   * include the ability to "Share Times" for a proposal.
   */
  baseEventDiffId?: Maybe<Scalars["ID"]>;
  /**
   * A block describing the consequences of the proposed changes to the user's
   * calendar corresponding to the currently selected scheduling option, if any
   */
  consequencesBlock?: Maybe<ConsequencesBlock>;
  /**
   * A list of diffs describing the proposed changes to the user's calendar
   * corresponding to the currently selected scheduling option, if any
   */
  diffBlocks: Array<DiffBlock>;
  /** @deprecated use executedTimeRanges instead */
  executedTimeRange?: Maybe<Array<Scalars["DateTimeInterval"]>>;
  executedTimeRanges?: Maybe<Array<Scalars["DateTimeInterval"]>>;
  id: Scalars["ID"];
  /** Whether this proposal is shared with others */
  isShared: Scalars["Boolean"];
  schedulingOptions: Array<ProposalOption>;
  selectedSchedulingOption?: Maybe<ProposalOption>;
  state: ProposalState;
};

export enum ProposalAppliesTo {
  AllInstances = "AllInstances",
  Instance = "Instance",
  ThisAndFuture = "ThisAndFuture",
}

export type ProposalOption = {
  __typename: "ProposalOption";
  /** An optional category for this scheduling option: availability issue, fixable conflict,  etc. */
  category?: Maybe<ProposalOptionCategory>;
  consequencesBlock?: Maybe<ConsequencesBlock>;
  id: Scalars["ID"];
  interval: Scalars["DateTimeInterval"];
  label: Scalars["String"];
  tradeoffBlocks: Array<TradeoffBlock>;
  type: ProposalOptionType;
};

export enum ProposalOptionCategory {
  AvailabilityIssue = "AvailabilityIssue",
  FixableConflict = "FixableConflict",
  Inconvenience = "Inconvenience",
  OutOfTimeRange = "OutOfTimeRange",
}

export enum ProposalOptionType {
  Additional = "Additional",
  Custom = "Custom",
  Initial = "Initial",
}

/** A proposal response from the assistant in response to a (re)schedule or update events request */
export type ProposalResponse = {
  __typename: "ProposalResponse";
  conversationId: Scalars["ID"];
  id: Scalars["ID"];
  proposal: Proposal;
};

export enum ProposalState {
  Active = "Active",
  Cancelled = "Cancelled",
  Completed = "Completed",
  Incomplete = "Incomplete",
  /** This proposal is created, but is still calculating suggestions. */
  LoadingSuggestions = "LoadingSuggestions",
  SoftConfirm = "SoftConfirm",
}

export enum ProposalStateEnum {
  Active = "ACTIVE",
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  Incomplete = "INCOMPLETE",
  SoftConfirm = "SOFT_CONFIRM",
}

export type PublicEventSummary = {
  __typename: "PublicEventSummary";
  attendees: Array<PublicPerson>;
  durationInMinutes: Scalars["Int"];
  oldTime?: Maybe<Scalars["DateTimeInterval"]>;
  time: Scalars["DateTimeInterval"];
  title: Scalars["String"];
};

export type PublicLinkMember = {
  __typename: "PublicLinkMember";
  id: Scalars["ID"];
  owner: Scalars["Boolean"];
  person: Person;
  required: Scalars["Boolean"];
  schedulingPriority?: Maybe<SchedulingPriorityEnum>;
};

export type PublicPerson = {
  __typename: "PublicPerson";
  primaryCalendarId: Scalars["ID"];
  profile: PublicProfile;
};

export type PublicProfile = {
  __typename: "PublicProfile";
  externalImageUrl?: Maybe<Scalars["String"]>;
  familyName: Scalars["String"];
  givenName: Scalars["String"];
};

export type PublicSchedulingLink = {
  __typename: "PublicSchedulingLink";
  allowAdditionalAttendees: Scalars["Boolean"];
  attendeeQuestions: Array<AttendeeQuestion>;
  bufferHours: Scalars["Int"];
  defaultDuration: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  durations: Array<Scalars["Int"]>;
  endOfAvailability: Scalars["DateTime"];
  groupSchedulingStrategy?: Maybe<GroupSchedulingStrategyEnum>;
  highlightBestTimes: Scalars["Boolean"];
  id: Scalars["ID"];
  linkLogoUrl?: Maybe<Scalars["String"]>;
  linkMembers: Array<PublicLinkMember>;
  linkName: Scalars["String"];
  linkTimes: Array<SuggestedSlot>;
  location?: Maybe<LocationTypeEnum>;
  managedByMe: Scalars["Boolean"];
  maxBookings?: Maybe<BookingLimit>;
  name: Scalars["String"];
  ownedByMe: Scalars["Boolean"];
  slug: Scalars["String"];
};

export type PublicSchedulingLinkLinkTimesArgs = {
  duration?: InputMaybe<Scalars["Int"]>;
  endDate: Scalars["Date"];
  startDate: Scalars["Date"];
  timeZone: Scalars["IANAZone"];
};

export type PublicSharedProposal = {
  __typename: "PublicSharedProposal";
  consequencesSummary?: Maybe<Scalars["String"]>;
  eventSummary: PublicEventSummary;
  owner: PublicPerson;
  ownerEcosystem?: Maybe<OwnerEcosystemEnum>;
  ownerTimeZone: Scalars["IANAZone"];
  proposalId: Scalars["ID"];
  state: ProposalStateEnum;
  tradeoffsSummary?: Maybe<Scalars["String"]>;
};

export type Query = {
  __typename: "Query";
  calendar?: Maybe<Calendar>;
  calendars: Array<Maybe<Calendar>>;
  chatHistory?: Maybe<ChatHistory>;
  currentCalendar?: Maybe<Calendar>;
  currentName: Scalars["String"];
  currentOrg?: Maybe<Org>;
  currentUser?: Maybe<User>;
  /** Fetches an event by externalEventId */
  event?: Maybe<Event>;
  /** Fetch multiple events by externalEventId */
  events: Array<Maybe<Event>>;
  /** @deprecated REMOVE once V2 is out: CW-7245 */
  flexibleMeetingsDashboard: FlexibleMeetingsDashboard;
  flexibleMeetingsDashboardV2: FlexibleMeetingsDashboardV2;
  group?: Maybe<Group>;
  hello: Scalars["String"];
  intentionallyError?: Maybe<Scalars["Boolean"]>;
  linkBooking?: Maybe<LinkBooking>;
  members: Array<Maybe<Member>>;
  paidFeatures?: Maybe<PaidFeatureState>;
  personalizedStatsRow?: Maybe<PersonalizedStatsRow>;
  proposal?: Maybe<Proposal>;
  /** Fetches a public scheduling link for creating a booking, accessible anonymously */
  publicSchedulingLink?: Maybe<PublicSchedulingLink>;
  publicSharedProposal?: Maybe<PublicSharedProposal>;
  rescheduleOptions: RescheduleOptions;
  searchAttendees: Array<SearchAttendeeResult>;
  searchInvitees: Array<Invitee>;
  searchMembers: MemberConnection;
  searchTeams: Array<Maybe<Team>>;
  suggestedInvitees: Array<Invitee>;
  supportedConferenceTypes: Array<SupportedConferenceType>;
  team?: Maybe<Team>;
  testDate: Scalars["Date"];
  testDateTime: Scalars["DateTime"];
  testInterval: Scalars["DateTimeInterval"];
  /** Settings for the current user. */
  userSettings?: Maybe<UserSettings>;
};

export type QueryCalendarArgs = {
  id: Scalars["ID"];
};

export type QueryCalendarsArgs = {
  ids: Array<Scalars["ID"]>;
};

export type QueryEventArgs = {
  calendarId?: InputMaybe<Scalars["ID"]>;
  id: Scalars["ID"];
};

export type QueryEventsArgs = {
  calendarId?: InputMaybe<Scalars["ID"]>;
  ids: Array<Scalars["ID"]>;
};

export type QueryGroupArgs = {
  id: Scalars["ID"];
};

export type QueryLinkBookingArgs = {
  id: Scalars["ID"];
};

export type QueryMembersArgs = {
  calendarIds: Array<Scalars["ID"]>;
};

export type QueryProposalArgs = {
  id: Scalars["ID"];
};

export type QueryPublicSchedulingLinkArgs = {
  linkName: Scalars["ID"];
  slug: Scalars["ID"];
};

export type QueryPublicSharedProposalArgs = {
  proposalId: Scalars["ID"];
};

export type QueryRescheduleOptionsArgs = {
  calendarId?: InputMaybe<Scalars["ID"]>;
  externalEventId: Scalars["ID"];
};

export type QuerySearchAttendeesArgs = {
  excludedCalendars: Array<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  query: Scalars["String"];
};

export type QuerySearchInviteesArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  query: Scalars["String"];
};

export type QuerySearchMembersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  query: Scalars["String"];
};

export type QuerySearchTeamsArgs = {
  input: TeamSearchInput;
};

export type QuerySuggestedInviteesArgs = {
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryTeamArgs = {
  id: Scalars["ID"];
};

export type QueryTestDateArgs = {
  date: Scalars["Date"];
};

export type QueryTestDateTimeArgs = {
  date: Scalars["DateTime"];
};

export type QueryTestIntervalArgs = {
  endTime: Scalars["DateTime"];
  startTime: Scalars["DateTime"];
};

export type QuestionResponseInput = {
  question: Scalars["String"];
  response: Scalars["String"];
};

export type Recurrence = {
  __typename: "Recurrence";
  description?: Maybe<Scalars["String"]>;
  type: RecurrenceType;
};

/**
 * A simplification of recurrence; we classify a recurrence by the frequency which is _greater or equal_ its actual frequency.
 *
 * examples
 * | Event             | RecurrenceType  |
 * | ----------------- | --------------- |
 * | every day         | Daily           |
 * | twice a week      | Weekly          |
 * | every other week  | Fortnightly     |
 * | every third week  | Monthly         |
 * | once a year       | LessThanMonthly |
 */
export enum RecurrenceType {
  Daily = "Daily",
  Fortnightly = "Fortnightly",
  LessThanMonthly = "LessThanMonthly",
  Monthly = "Monthly",
  Weekly = "Weekly",
}

export type RefineProposalInput = {
  proposalId: Scalars["ID"];
  userMessage: Scalars["String"];
};

export type RemoveTeamMembersInput = {
  memberIds: Array<Scalars["ID"]>;
  teamId: Scalars["ID"];
};

export type RemoveTradeoffFromProposalInput = {
  eventDiffId: Scalars["ID"];
};

export type RescheduleBookingInput = {
  id: Scalars["ID"];
  reason?: InputMaybe<Scalars["String"]>;
  timeRange: Scalars["DateTimeInterval"];
};

export type RescheduleEventInput = {
  externalEventId: Scalars["ID"];
  startTime: Scalars["DateTime"];
  timeZone: Scalars["IANAZone"];
};

export type RescheduleOptions = {
  __typename: "RescheduleOptions";
  rescheduleOptions: Array<SchedulingOption>;
};

export type ReschedulingLink = {
  __typename: "ReschedulingLink";
  reschedulingLinkUrl: Scalars["String"];
  schedulingLink: SchedulingLinkWithUrl;
};

export enum ResponseStatusEnum {
  Accepted = "Accepted",
  Declined = "Declined",
  NeedsAction = "NeedsAction",
  NotApplicable = "NotApplicable",
  Tentative = "Tentative",
}

export type RollingWindow = {
  __typename: "RollingWindow";
  windowSize?: Maybe<Scalars["Int"]>;
};

export type Room = {
  __typename: "Room";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type SaveLinkSignInWaitlistInput = {
  anonymousId: Scalars["String"];
  email: Scalars["String"];
  linkName: Scalars["String"];
  slug: Scalars["String"];
};

export type SchedulingLink = {
  __typename: "SchedulingLink";
  active: Scalars["Boolean"];
  allowAdditionalAttendees: Scalars["Boolean"];
  allowBookingOnNoMeetingDay: Scalars["Boolean"];
  allowQuarterHourBooking: Scalars["Boolean"];
  attendeeQuestions: Array<AttendeeQuestion>;
  availabilityRange: AvailabilityRange;
  availabilityRestriction: AvailabilityRestrictionEnum;
  bookableHours: BookableHoursEnum;
  bufferHours: Scalars["Int"];
  customMeetingHours?: Maybe<CustomMeetingHours>;
  defaultDuration: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  durations: Array<Scalars["Int"]>;
  groupSchedulingStrategy?: Maybe<GroupSchedulingStrategyEnum>;
  highlightBestTimes: Scalars["Boolean"];
  id: Scalars["ID"];
  linkMembers: Array<LinkMember>;
  linkTimes: Array<SuggestedSlot>;
  location: LocationTypeEnum;
  maxBookings?: Maybe<BookingLimit>;
  name: Scalars["String"];
  owner: Member;
  singleUse: Scalars["Boolean"];
  slug: Scalars["String"];
  textOnlyTitle: Scalars["Boolean"];
  titleTemplate: Scalars["String"];
};

export type SchedulingLinkLinkTimesArgs = {
  duration?: InputMaybe<Scalars["Int"]>;
  endDate: Scalars["Date"];
  linkName: Scalars["String"];
  startDate: Scalars["Date"];
  timeZone: Scalars["IANAZone"];
};

export type SchedulingLinkInput = {
  active: Scalars["Boolean"];
  allowAdditionalAttendees: Scalars["Boolean"];
  allowBookingOnNoMeetingDay: Scalars["Boolean"];
  allowQuarterHourBooking: Scalars["Boolean"];
  attendeeQuestions: Array<AttendeeQuestionInput>;
  availabilityRange: AvailabilityRangeInput;
  availabilityRestriction: AvailabilityRestrictionEnum;
  bookableHours: BookableHoursEnum;
  bufferHours: Scalars["Int"];
  customMeetingHours?: InputMaybe<CustomMeetingHoursInput>;
  defaultDuration: Scalars["Int"];
  description?: InputMaybe<Scalars["String"]>;
  durations: Array<Scalars["Int"]>;
  groupSchedulingStrategy?: InputMaybe<GroupSchedulingStrategyEnum>;
  highlightBestTimes: Scalars["Boolean"];
  linkMembers: Array<LinkMemberInput>;
  location?: InputMaybe<LocationTypeEnum>;
  maxBookings?: InputMaybe<BookingLimitInput>;
  name: Scalars["String"];
  /** Whether this link can only be used for a single booking. */
  singleUse: Scalars["Boolean"];
  slug: Scalars["String"];
  textOnlyTitle: Scalars["Boolean"];
  titleTemplate: Scalars["String"];
};

/** Metadata for a scheduling link */
export type SchedulingLinkMetadata = {
  __typename: "SchedulingLinkMetadata";
  link: SchedulingLinkWithUrl;
  operation: SchedulingLinkOperation;
};

export enum SchedulingLinkOperation {
  Create = "Create",
  Find = "Find",
  SetActive = "SetActive",
  SetInactive = "SetInactive",
  Update = "Update",
}

export type SchedulingLinkWithUrl = {
  __typename: "SchedulingLinkWithUrl";
  id: Scalars["ID"];
  /**
   * The linkName that must be used to fetch details about this link.
   * May be different than the linkName of the user loading the link when the user is a member of a group link.
   * For example, I may be a member of the group link, but the creator of the group link may have a different linkName.
   * In this case, the creators linkName must be used. In short, always use the linkName returned by the BE and
   * not the linkName off of the user object.
   */
  linkName: Scalars["String"];
  schedulingLink: SchedulingLink;
  url: Scalars["String"];
};

/** A response from the assistant for creating or updating scheduling link(s) request */
export type SchedulingLinksResponse = {
  __typename: "SchedulingLinksResponse";
  conversationId: Scalars["ID"];
  id: Scalars["ID"];
  linksMetadata: Array<SchedulingLinkMetadata>;
  responseText: Scalars["String"];
};

export type SchedulingOption = {
  __typename: "SchedulingOption";
  start: Scalars["DateTime"];
};

export enum SchedulingPriorityEnum {
  DoNotSchedule = "DoNotSchedule",
  High = "High",
  Low = "Low",
  Medium = "Medium",
}

export type SearchAttendeeResult = Group | Member | Team;

export type SendInviteInput = {
  calendarIds: Array<Scalars["ID"]>;
  note?: InputMaybe<Scalars["String"]>;
};

export type SendProposeNewTimeEmailInput = {
  isPreview?: InputMaybe<Scalars["Boolean"]>;
  proposalId: Scalars["ID"];
  reasonForProposing?: InputMaybe<Scalars["String"]>;
};

/** TODO (lsanwick) Consolidate this with Recurrence. */
export type SimpleRecurrence = {
  __typename: "SimpleRecurrence";
  count: Scalars["Int"];
  description: Scalars["String"];
  type: RecurrenceType;
};

export type SlackAccount = {
  __typename: "SlackAccount";
  displayName: Scalars["String"];
  id: Scalars["ID"];
  publicChannels: Array<SlackChannel>;
  slackTeamId: Scalars["ID"];
};

export type SlackChannel = {
  __typename: "SlackChannel";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type SlackSettings = {
  __typename: "SlackSettings";
  channelId?: Maybe<Scalars["String"]>;
  channelName?: Maybe<Scalars["String"]>;
  notificationEnabled: Scalars["Boolean"];
  notificationTime?: Maybe<Scalars["Time"]>;
};

export type SmartHold = {
  __typename: "SmartHold";
  holdType: SmartHoldType;
  id: Scalars["ID"];
  state: SmartHoldState;
};

export enum SmartHoldState {
  Paused = "Paused",
  Protected = "Protected",
  Unpaused = "Unpaused",
}

export enum SmartHoldType {
  FocusTime = "FocusTime",
  Lunch = "Lunch",
  MeetingRelief = "MeetingRelief",
  TravelTime = "TravelTime",
}

export enum SubscriptionType {
  Expired = "Expired",
  FreemiumOrg = "FreemiumOrg",
  LegacyPrePaywall = "LegacyPrePaywall",
  PaidBillingGroup = "PaidBillingGroup",
  PaidOrgPro = "PaidOrgPro",
  PaidTeamPro = "PaidTeamPro",
  PaidUserPro = "PaidUserPro",
  SelfServePremiumOrgQ42020 = "SelfServePremiumOrgQ42020",
  SelfServePremiumTeamQ12021 = "SelfServePremiumTeamQ12021",
}

export type SuggestedSlot = {
  __typename: "SuggestedSlot";
  id: Scalars["ID"];
  tag?: Maybe<SuggestedSlotTagEnum>;
  timeSlot: Scalars["DateTimeInterval"];
};

export enum SuggestedSlotTagEnum {
  Best = "Best",
  Inconvenient = "Inconvenient",
}

export type SuggestedTeam = {
  __typename: "SuggestedTeam";
  id: Scalars["ID"];
  reasons: Array<SuggestedTeamReason>;
  team: Team;
};

export type SuggestedTeamReason = {
  __typename: "SuggestedTeamReason";
  type: SuggestedTeamReasonType;
  value: Scalars["Int"];
};

export enum SuggestedTeamReasonType {
  NumMeetingsWithOverlap = "NumMeetingsWithOverlap",
  NumOneOnOnesWithOverlap = "NumOneOnOnesWithOverlap",
}

export enum SupportedConferenceType {
  Meet = "Meet",
  TeamsForBusiness = "TeamsForBusiness",
  Zoom = "Zoom",
}

export type Team = {
  __typename: "Team";
  calendarId?: Maybe<Scalars["ID"]>;
  disruptiveEvents: Array<Event>;
  id: Scalars["ID"];
  isPaid: Scalars["Boolean"];
  isPrimary: Scalars["Boolean"];
  manageTeamCalendar: Scalars["Boolean"];
  members: Array<TeamMember>;
  name: Scalars["String"];
  noMeetingDay: NoMeetingDayOptions;
  slackAccount?: Maybe<SlackAccount>;
  slackSettings?: Maybe<SlackSettings>;
  syncWFHEvents: Scalars["Boolean"];
  /** If the team has a shared calendar, this provides some details about it. Only populated for Microsoft orgs. */
  teamCalendar?: Maybe<TeamCalendarDetails>;
  useNoMeetingDay: Scalars["Boolean"];
  useTeamCalendar: Scalars["Boolean"];
};

export type TeamDisruptiveEventsArgs = {
  end: Scalars["Date"];
  start: Scalars["Date"];
  timeZone: Scalars["IANAZone"];
};

export type TeamCalendarDetails = {
  __typename: "TeamCalendarDetails";
  calendarName: Scalars["String"];
  members: Array<TeamCalendarMember>;
  owner: Person;
  teamId: Scalars["String"];
};

export type TeamCalendarMember = {
  __typename: "TeamCalendarMember";
  personId: Scalars["String"];
  status: TeamCalendarMemberInviteStatus;
};

export enum TeamCalendarMemberInviteStatus {
  Accepted = "Accepted",
  NotInvited = "NotInvited",
  Pending = "Pending",
  Unknown = "Unknown",
}

export type TeamMember = {
  __typename: "TeamMember";
  admin: Scalars["Boolean"];
  focusTimeSettings?: Maybe<FocusTimeSettings>;
  id: Scalars["ID"];
  member?: Maybe<Member>;
  status: TeamMemberStatus;
  timeZone?: Maybe<Scalars["IANAZone"]>;
};

export enum TeamMemberStatus {
  Active = "Active",
  Invited = "Invited",
}

export type TeamSearchInput = {
  excludeMyTeams?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  query: Scalars["String"];
};

export type TestBar = {
  __typename: "TestBar";
  actor: TestEntity;
  definitelyFruit: TestFruit;
  definitelyStr: Scalars["String"];
  id: Scalars["ID"];
  maybeActor?: Maybe<TestEntity>;
  maybeFruit?: Maybe<TestFruit>;
  maybeStr?: Maybe<Scalars["String"]>;
};

export type TestEntity = TestHuman | TestRobot;

export type TestFoo = {
  __typename: "TestFoo";
  actor: TestEntity;
  definitelyFruit: TestFruit;
  definitelyStr: Scalars["String"];
  id: Scalars["ID"];
  maybeActor?: Maybe<TestEntity>;
  maybeFruit?: Maybe<TestFruit>;
  maybeStr?: Maybe<Scalars["String"]>;
};

export enum TestFruit {
  Apple = "APPLE",
  Banana = "BANANA",
  Cherry = "CHERRY",
}

export type TestHuman = {
  __typename: "TestHuman";
  birthDate: Scalars["String"];
  name: Scalars["String"];
};

export type TestRobot = {
  __typename: "TestRobot";
  createdOn: Scalars["String"];
  name: Scalars["String"];
};

/** A text response from the assistant */
export type TextResponse = {
  __typename: "TextResponse";
  conversationId: Scalars["ID"];
  id: Scalars["ID"];
  text: Scalars["String"];
  type: TextResponseType;
};

export enum TextResponseType {
  Basic = "Basic",
  Markdown = "Markdown",
}

/** A range of times within a day, potentially unbounded when start or end is null. */
export type TimeRange = {
  __typename: "TimeRange";
  end?: Maybe<Scalars["Time"]>;
  start?: Maybe<Scalars["Time"]>;
};

export type TimeRangeInput = {
  end: Scalars["Time"];
  start: Scalars["Time"];
};

/** A specific scheduling tradeoff for a diff */
export type Tradeoff = {
  __typename: "Tradeoff";
  attendees: Array<Attendee>;
  diffId?: Maybe<Scalars["ID"]>;
  event?: Maybe<Event>;
  /** Why this meeting could not be rescheduled, thus making it a tradeoff. */
  reasonForInaction: TradeoffInactionReason;
  /** The title of the tradeoff, includes the pill structuring. */
  title: Scalars["String"];
  /** Null if the tradeoff's time is not being updated */
  updatedTime?: Maybe<DateOrTimeRange>;
};

/** A summary of scheduling tradeoffs for a diff */
export type TradeoffBlock = {
  __typename: "TradeoffBlock";
  /** All attendees in the list of tradeoffs affected. */
  affectedAttendees: Array<Attendee>;
  title: Scalars["String"];
  tradeoffType: TradeoffType;
  /** List of granular scheduling tradeoffs */
  tradeoffs: Array<Tradeoff>;
};

export enum TradeoffInactionReason {
  CannotEdit = "CannotEdit",
  ExternalMeeting = "ExternalMeeting",
  IsActionable = "IsActionable",
  LowVisibility = "LowVisibility",
  Other = "Other",
}

export enum TradeoffType {
  AvailabilityIssue = "AvailabilityIssue",
  FixableConflict = "FixableConflict",
  Inconvenience = "Inconvenience",
  LowVisibilityInconvenience = "LowVisibilityInconvenience",
  OutOfTimeRange = "OutOfTimeRange",
}

export enum TransparencyEnum {
  None = "None",
  Opaque = "Opaque",
  Tentative = "Tentative",
  Transparent = "Transparent",
}

export type TravelTimeSettings = {
  __typename: "TravelTimeSettings";
  enabled: Scalars["Boolean"];
  id: Scalars["ID"];
  notify: Scalars["Boolean"];
};

export type TrialMetadata = {
  __typename: "TrialMetadata";
  isTrialExpired: Scalars["Boolean"];
  timeZone: Scalars["IANAZone"];
  trialEndDate: Scalars["Date"];
};

export type UnpauseEventFlexibilityInput = {
  externalEventId: Scalars["ID"];
};

export type UnsyncSmartHoldInput = {
  externalEventId: Scalars["ID"];
};

export type UpdateCustomCollaboratorsInput = {
  calendarIds: Array<Scalars["ID"]>;
};

export type UpdateEventCategoryTypeInput = {
  category: EventCategoryType;
  externalEventId: Scalars["ID"];
};

export type UpdateEventInput = {
  appliesTo: EditEventAppliesTo;
  attendees?: InputMaybe<Array<EventAttendeeInput>>;
  conferenceType?: InputMaybe<ConferencingType>;
  description?: InputMaybe<Scalars["String"]>;
  externalEventId: Scalars["ID"];
  location?: InputMaybe<Scalars["String"]>;
  recurrenceRule?: InputMaybe<Scalars["RecurrenceRule"]>;
  removeAttendees?: InputMaybe<Array<Scalars["String"]>>;
  timeRange?: InputMaybe<Scalars["DateTimeInterval"]>;
  timeZone?: InputMaybe<Scalars["IANAZone"]>;
  title?: InputMaybe<Scalars["String"]>;
  upsertAttendees?: InputMaybe<Array<EventAttendeeInput>>;
};

export type UpdateEventResponseInput = {
  externalEventId: Scalars["ID"];
  responseStatus: ResponseStatusEnum;
};

export type UpdateEventsFlexibilityInput = {
  events: Array<EventFlexibilityInput>;
};

export type UpdateLinkActiveInput = {
  active: Scalars["Boolean"];
  linkName: Scalars["String"];
  slug: Scalars["ID"];
};

export type UpdatePreferredVideoConferenceInput = {
  preferredVideoConference: PreferredVideoConference;
};

export type UpdateProposalInput = {
  attendees?: InputMaybe<Array<EventAttendeeInput>>;
  conferenceType?: InputMaybe<ConferencingType>;
  description?: InputMaybe<Scalars["String"]>;
  flexDetails?: InputMaybe<FlexDetailsInput>;
  id: Scalars["ID"];
  location?: InputMaybe<Scalars["String"]>;
  recurrenceRule?: InputMaybe<Scalars["RecurrenceRule"]>;
  searchTimeRanges?: InputMaybe<Array<Scalars["DateTimeInterval"]>>;
  timeRange?: InputMaybe<Scalars["DateTimeInterval"]>;
  timeZone?: InputMaybe<Scalars["IANAZone"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type UpdateSchedulingLinkInput = {
  id: Scalars["ID"];
  schedulingLink: SchedulingLinkInput;
};

export type UpdateTeamNameInput = {
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type UpdateTeamSlackInput = {
  channelId?: InputMaybe<Scalars["String"]>;
  channelName?: InputMaybe<Scalars["String"]>;
  notificationEnabled?: InputMaybe<Scalars["Boolean"]>;
  notificationTime?: InputMaybe<Scalars["Time"]>;
  teamId: Scalars["ID"];
};

export type UpdateUserWorkingHoursInput = {
  saveMeetingAndWorkingHours?: InputMaybe<Scalars["Boolean"]>;
  timeZone: Scalars["IANAZone"];
  workingHours: Array<WorkingHoursInput>;
};

export type UpdateWorkingLocationEventInput = {
  calendarId: Scalars["ID"];
  externalEventId: Scalars["ID"];
  patchAppliesTo: EditEventAppliesTo;
  workingLocationEvent: WorkingLocationEventInput;
};

export type UploadCustomLinkLogoInput = {
  logoImage: Scalars["Upload"];
};

export type UploadLinkLogoForBillingGroupInput = {
  billingGroupId: Scalars["ID"];
  logoImage: Scalars["Upload"];
};

/** A Clockwise user. */
export type User = {
  __typename: "User";
  customCollaborators?: Maybe<Array<Collaborator>>;
  eventCategories: Array<EventCategory>;
  focusTimeSettings: FocusTimeSettings;
  /** User id */
  id: Scalars["ID"];
  linkLogoUrl?: Maybe<Scalars["String"]>;
  linkName?: Maybe<Scalars["String"]>;
  lunchHoldSettings: LunchHoldSettings;
  meetingReliefSettings: MeetingReliefSettings;
  member: Member;
  resolvedLinkLogoUrl?: Maybe<Scalars["String"]>;
  schedulingLink?: Maybe<SchedulingLink>;
  schedulingLinks: Array<SchedulingLinkWithUrl>;
  /** Suggested teams for the current user, ordered by score */
  suggestedTeams: Array<SuggestedTeam>;
  teams: Array<Maybe<Team>>;
  topCollaborators?: Maybe<Array<Collaborator>>;
  travelTimeSettings: TravelTimeSettings;
  zoomAccount?: Maybe<ZoomAccount>;
};

/** A Clockwise user. */
export type UserCustomCollaboratorsArgs = {
  timeZone?: InputMaybe<Scalars["IANAZone"]>;
};

/** A Clockwise user. */
export type UserSchedulingLinkArgs = {
  linkName?: InputMaybe<Scalars["String"]>;
  slug: Scalars["ID"];
};

/** A Clockwise user. */
export type UserSchedulingLinksArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
};

/** A Clockwise user. */
export type UserTopCollaboratorsArgs = {
  end: Scalars["Date"];
  start: Scalars["Date"];
  timeZone: Scalars["IANAZone"];
};

export type UserMessage = {
  __typename: "UserMessage";
  id: Scalars["ID"];
  text: Scalars["String"];
};

export type UserProfileInput = {
  externalImageUrl?: InputMaybe<Scalars["String"]>;
  familyName: Scalars["String"];
  givenName: Scalars["String"];
  id: Scalars["ID"];
};

export type UserSettings = {
  __typename: "UserSettings";
  id: Scalars["ID"];
  notifyOthersWhenMarkingFlexible: Scalars["Boolean"];
  notifyWhenOthersMarkFlexible: Scalars["Boolean"];
  preferredVideoConference: PreferredVideoConference;
  receiveFlexibleMeetingSummary: Scalars["Boolean"];
  receiveWeekInReview: Scalars["Boolean"];
};

export type ValidateTeamSlackChannelPayload = {
  __typename: "ValidateTeamSlackChannelPayload";
  channel?: Maybe<SlackChannel>;
  isValid: Scalars["Boolean"];
};

/** A view events response from the assistant in response to a query for events */
export type ViewEventsResponse = {
  __typename: "ViewEventsResponse";
  conversationId: Scalars["ID"];
  id: Scalars["ID"];
  text: Scalars["String"];
};

export enum VisibilityEnum {
  Default = "Default",
  Private = "Private",
  Public = "Public",
}

export type WeekAnalytics = {
  __typename: "WeekAnalytics";
  conflictsResolved: Scalars["Int"];
  focusTimeHeld?: Maybe<Scalars["Duration"]>;
  meetingTime?: Maybe<Scalars["Duration"]>;
};

export type WorkingHours = {
  __typename: "WorkingHours";
  date: Scalars["Date"];
  id: Scalars["ID"];
  timeRange?: Maybe<Array<TimeRange>>;
  /** The enclosing range of working hours for a day, ignoring split hours. */
  uninterruptedRange?: Maybe<TimeRange>;
  weekDay: Scalars["String"];
};

export type WorkingHoursInput = {
  uninterruptedRange: TimeRangeInput;
  weekday: Scalars["String"];
};

export type WorkingLocation = {
  __typename: "WorkingLocation";
  address?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  timeZone?: Maybe<Scalars["IANAZone"]>;
  type: WorkingLocationType;
};

export type WorkingLocationEvent = {
  __typename: "WorkingLocationEvent";
  calendarId: Scalars["ID"];
  eventId: Scalars["ID"];
  id: Scalars["ID"];
  isAllDay: Scalars["Boolean"];
  location: WorkingLocation;
  range: DateOrTimeRange;
  recurrence?: Maybe<Recurrence>;
  recurrenceRule?: Maybe<Scalars["RecurrenceRule"]>;
  recurringEventId?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
};

export type WorkingLocationEventInput = {
  isAllDay: Scalars["Boolean"];
  location: WorkingLocationInput;
  range: Scalars["DateTimeInterval"];
  recurrenceRule?: InputMaybe<Scalars["RecurrenceRule"]>;
  timeZone: Scalars["IANAZone"];
  title?: InputMaybe<Scalars["String"]>;
};

export type WorkingLocationInput = {
  address?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
  timeZone?: InputMaybe<Scalars["IANAZone"]>;
  type: WorkingLocationType;
};

export enum WorkingLocationType {
  Home = "Home",
  Office = "Office",
  Other = "Other",
}

export type ZoomAccount = {
  __typename: "ZoomAccount";
  displayName: Scalars["String"];
  id: Scalars["ID"];
  owner: User;
  valid: Scalars["Boolean"];
};

export type DisablePersonalSyncResponse = {
  __typename: "disablePersonalSyncResponse";
  success: Scalars["Boolean"];
};
