export const DEFAULT_LAUNCH_DARKLY_FLAGS = {
  erin_test_flag_react: false,
  ShowChatDebugInfo: false,
  CustomJoinAPlanRequest: false,
  ClockwiseAI: false,
  EnableWelcomePageCanonicalTag: false,
  MultiCalendar: true,
  GatewayMonetization: false,
  GatewayPersonalizedStats: false,
  BrowserNotifications: false,
  PlannerMini: false,
  SlackDogfoodApp: false,
  ShareableProposals: false,
  WorkingLocation: false,
  MaxTeamSizeOverride: 50,
  EnableHelpScoutBeacon: false,
  ScheduleWithAiButton: false,
  "rollout-drag-and-drop-on-calendar": false,
  "rollout-resize-on-calendar": false,
  "kill-switch-g-cal-injections": false,
  "kill-switch-event-modal-observers": false,
  "killswitch-extension-sidecar": false,
  DisableFullStory: true,
  PreferredVideoConference: false,
  LinksClone: false,
  ClickToCreate: false,
  DNDProposal: false,
  CustomRecurrenceUI: false,
  ProposalSuggestionsInCalendar: false,
  CommandBar: false,
  PrismEducationModal: false,
  onDemandDefrag: false,
  PMFSurvey: false,
  PMFSurveyNewUsers: false,
  "NUX-Q1-2025": false,
  "onboarding-schedule-question": false,
  TimeSuggestionsTab: false,
  SearchTimeRanges: false,
  RefineProposal: false,
} as const;

export type LaunchDarklyNameType = keyof typeof DEFAULT_LAUNCH_DARKLY_FLAGS;
export type LaunchDarklyMappingType = {
  [key in LaunchDarklyNameType]: boolean | number | string;
};
