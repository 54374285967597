import {
  RescheduleEventModalActions,
  SelectedRescheduleOption,
  SelectedRescheduleOptionEntrypoint,
} from "../actions/reschedule-event-modal.actions";

export type IRescheduleEventModalState = {
  selectedRescheduleOption: {
    externalEventId: string;
    calendarId: string;
    duration: number;
    title: string;
    proposedStartTimeISO: string;
    currentStartTimeISO: string;
    id: string;
    entryPoint: SelectedRescheduleOptionEntrypoint;
    showACopyOfNewProposedEvent?: boolean;
    hideOriginalEvent?: boolean;
    newDuration?: number;
    showFindMoreTimes?: boolean;
    isRecurring?: boolean;
    recurringEventId?: string | null;
    isHold?: boolean;
    isSaving?: boolean;
  } | null;
};

export const initialRescheduleEventModalState: IRescheduleEventModalState = {
  selectedRescheduleOption: null,
};

export interface IRescheduleEventModalActions {
  type: RescheduleEventModalActions;
  data: {
    selectedRescheduleOption: SelectedRescheduleOption | null;
    externalEventId?: string;
  };
}

export function rescheduleEventModalReducer(
  state: IRescheduleEventModalState = initialRescheduleEventModalState,
  action: IRescheduleEventModalActions,
): IRescheduleEventModalState {
  switch (action.type) {
    case RescheduleEventModalActions.AddSelectedRescheduleOption:
      return {
        ...state,
        selectedRescheduleOption: action.data.selectedRescheduleOption,
      };
    case RescheduleEventModalActions.ClearSpecificSelectedRescheduleOption:
      if (
        state.selectedRescheduleOption &&
        state.selectedRescheduleOption.externalEventId === action.data.externalEventId
      ) {
        return {
          ...state,
          selectedRescheduleOption: null,
        };
      }
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
}
